<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              v-model="filter.nickname"
              size="lg"
              placeholder="Nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="options.statuses"
              size="lg"
            ></b-form-select>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary btn-md"
            @click="getDesignManagementPlans(true)"
          >
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <complete-table :loading="loading" :data="currentDesignManagementPlans">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 250px" class="pl-7">
                <span class="text-dark-75">Project</span>
              </th>
              <th style="min-width: 350px">Project</th>
              <th style="min-width: 100px">Status</th>
              <th style="min-width: 350px">Owner</th>
              <th style="min-width: 350px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{ item, i }">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.code }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.name }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.status }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.owner }}
                </span>
              </td>

              <td>
                <div class="d-flex">
                  <a
                    class="btn btn-light-info font-weight-bolder text-center font-size-sm mr-2"
                    @click="toEditPage(item)"
                  >
                    <i style="font-size:22px" class="flaticon-edit"></i>
                  </a>

                  <a
                    class="btn btn-light-primary font-weight-bolder  text-center font-size-sm mr-2"
                    @click="showRevisionModal(item)"
                  >
                    <img :src="RecyclePencil" width="22" height="22" />
                  </a>
                  <a
                    class="btn btn-light-success font-weight-bolder text-center font-size-sm mr-2"
                    @click="showUploadModal(item)"
                  >
                    <i style="font-size:22px" class="flaticon-upload"></i>
                  </a>
                </div>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="revisi-dmp" hide-footer title="DMP Revision">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">DMP Last Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              :value="
                selectedProject
                  ? selectedProject.design_management_plan.number
                  : null
              "
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Revision DMP Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="currentForm.number"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Last Revision Number</label>

            <b-form-input
              disabled
              id="input-large"
              size="lg"
              :value="
                selectedProject
                  ? selectedProject.design_management_plan.revision
                  : null
              "
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Revision Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="currentForm.revision"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Revision Date</label>
            <b-form-datepicker
              id="input-large"
              size="lg"
              v-model="currentForm.revision_date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="DD-MM-YYYY"
              reset-button
              reset-value=""
              :no-flip="true"
            ></b-form-datepicker>
          </div>
        </div>

        <div class="d-flex mt-2">
          <b-button
            class="btn btn-success mr-2"
            block
            :disabled="loading"
            @click="revision"
            >Save</b-button
          >

          <b-button
            class="btn btn-secondary mt-0"
            block
            :disabled="loading"
            @click="closeRevisionModal"
            >Cancel</b-button
          >
        </div>
      </div>
    </b-modal>

    <b-modal ref="upload-dmp" hide-footer title="Upload DMP">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Last DMP Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              :value="
                selectedProject
                  ? selectedProject.design_management_plan.number
                  : '-'
              "
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Revision DMP Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="currentForm.number"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Last Revision Number</label>

            <b-form-input
              disabled
              id="input-large"
              size="lg"
              :value="
                selectedProject
                  ? selectedProject.design_management_plan.revision
                  : '-'
              "
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Revision Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="currentForm.revision"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">Revision Date</label>
            <b-form-datepicker
              id="input-large"
              size="lg"
              v-model="currentForm.revision_date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="id"
              placeholder="DD-MM-YYYY"
              reset-button
              reset-value=""
              :no-flip="true"
            ></b-form-datepicker>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">EVP Engineering</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="currentForm.evp_name"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">EVP Project</label>
            <b-form-input
              id="input-large"
              size="lg"
              v-model="currentForm.vp_name"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large">File</label>

            <b-form-group>
              <v-file-input
                show-size
                label="File input"
                @change="currentFile"
              ></v-file-input>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex mt-2">
          <b-button class="btn btn-success mr-2" block @click="revision()">
            >Save</b-button
          >

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeUploadModal"
            >Cancel</b-button
          >
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import {
  GET_DESIGN_MANAGEMENT_PLAN,
  UPDATE_REVISION_DESIGN_MANAGEMENT_PLAN,
  UPLOAD_REVISION_DESIGN_MANAGEMENT_PLAN,
} from "../../../core/services/store/design-management-plan.module";
import ApiService from "../../../core/services/api.service";
import { showToast } from "../../../core/helper/toast.helper";

export default {
  name: "DesignManagementPlan",
  components: { CompleteTable },
  data() {
    return {
      RecyclePencil: require("@/assets/icons/pencilRefresh.svg"),
      filter: {
        status: null,
        nickname: null,
        job_desk: ["design", "admin"],
      },
      fileData: null,
      currentForm: {
        id: null,
        number: null,
        revision_dmp_number: null,
        revision: null,
        revision_date: null,
        evp_name: null,
        vp_name: null,
        file: null,
      },
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" },
        ],
      },
      selectedProject: null,
      data: {
        projects: [
          {
            id: 1,
            code: "LR98123",
            name: "PEMBANGUNAN SISTEM PERSINYALAN",
            status: "DRAFT",
            owner: "BALAI TEKNIK PERKERETAAPIAN",
          },
        ],
      },
    };
  },

  computed: {
    ...mapState({
      errors: (state) => state.designManagementPlan.errors,
      loading: (state) => state.designManagementPlan.loading,
      designManagementPlan: (state) =>
        state.designManagementPlan.designManagementPlan,
      currentDesignManagementPlan: (state) =>
        state.designManagementPlan.currentDesignManagementPlan,
    }),

    currentDesignManagementPlans() {
      return this.designManagementPlan?.data ?? [];
    },
  },

  methods: {
    getDesignManagementPlans(filter = false) {
      if (!arrayMoreThanOne(this.currentDesignManagementPlans) || filter) {
        this.$store.dispatch(GET_DESIGN_MANAGEMENT_PLAN, {
          params: this.filter,
        });
      }
    },

    upload(index, key) {
      this.data.projects[index][key] = "https://google.com";
    },

    deleteFile(index, key) {
      this.data.projects[index][key] = null;
    },

    showRevisionModal(selectedProject) {
      ApiService.setHeader();

      ApiService.query(
        `project/${selectedProject?.code}/design/management/plan/detail`
      )
        .then(({ data }) => {
          this.selectedProject = data?.data;

          this.currentForm.revision_date =
            data?.data?.design_management_plan?.revision_date;
          this.currentForm.evp_name =
            data?.data?.design_management_plan?.evp_name;
          this.currentForm.vp_name =
            data?.data?.design_management_plan?.vp_name;
        })
        .catch((response) => {
          if (response.message === "design management plan not found") {
            showToast("Warning", "Design management plan not found", "danger");
          } else {
            showToast("Warning", "Failed Loading Data", "danger");
          }
        });

      this.$refs["revisi-dmp"].show();
    },

    closeRevisionModal() {
      this.selectedProject = null;

      this.$refs["revisi-dmp"].hide();

      this.currentForm = {
        id: null,
        number: null,
        revision: null,
        revision_date: null,
        evp_name: null,
        vp_name: null,
        file: null,
      };
    },

    showUploadModal(selectedProject) {
      ApiService.setHeader();
      console.log(selectedProject);
      ApiService.query(
        `project/${selectedProject?.code}/design/management/plan/detail`
      )
        .then(({ data }) => {
          this.selectedProject = data?.data;

          this.currentForm.revision_date =
            data?.data?.design_management_plan?.revision_date;

          this.currentForm.evp_name =
            data?.data?.design_management_plan?.evp_name;

          this.currentForm.vp_name =
            data?.data?.design_management_plan?.vp_name;

          this.currentForm.number = data?.data?.design_management_plan?.number;

          this.currentForm.revision =
            data?.data?.design_management_plan?.revision;
        })
        .catch((response) => {
          if (response.message === "design management plan not found") {
            showToast("Warning", "Design management plan not found", "danger");
          } else {
            showToast("Warning", "Failed Loading Data", "danger");
          }
        });
      this.$refs["upload-dmp"].show();
    },

    closeUploadModal() {
      this.selectedProject = null;

      this.$refs["upload-dmp"].hide();
    },

    toEditPage(item) {
      this.$router.push({
        name: "project-design-management-plan-edit",
        params: {
          code: item.code,
        },
      });
    },
    currentFile(file) {
      this.fileData = file;
    },
    revision() {
      this.$store
        .dispatch(UPDATE_REVISION_DESIGN_MANAGEMENT_PLAN, {
          design_management_plan: {
            ...this.currentForm,
            id: this.selectedProject.design_management_plan.id,
          },
        })
        .then(() => {
          this.closeRevisionModal();
          this.closeUploadModal();
        });
      this.uploadDocument(this.fileData);
    },
    uploadDocument(files) {
      if (files !== undefined) {
        let formData = new FormData();
        formData.append("file", files);
        formData.append("project_code", this.selectedProject.project.code);
        this.$store
          .dispatch(UPLOAD_REVISION_DESIGN_MANAGEMENT_PLAN, formData)
          .catch((error) => {
            showToast("Notification", error.data.message, "danger");
          });
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Design Management Plan" }]);

    this.getDesignManagementPlans();
  },
};
</script>

<style>
.filter-blue {
  fill: red;
}
</style>
